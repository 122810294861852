import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { Group, SaveGroupRequest } from '@/client/groups';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { GroupForm } from '@/components/groups/forms';
import { useGroup, useUpdateGroup } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { GroupsTabs } from '@/pages/groups/GroupsTabs';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import { queryStateConverter } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../LoadingPage';

export const UpdateGroupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const { cannot } = usePermission();

  const { group, isLoading } = useGroup({
    groupId: id,
    accountId: account?.id,
  });
  const updateGroup = useUpdateGroup();

  const [initialValues, setInitialValues] = useState<SaveGroupRequest>({
    name: '',
    autoAssign: false,
    account: '',
    eFrontSync: true,
    isForPhishing: false,
  });

  useEffect(() => {
    if (!group) return;

    setInitialValues({
      name: group.name,
      autoAssign: group.autoAssign,
      account: group?.account?.id || '',
      eFrontSync: true,
      isForPhishing: group?.isForPhishing,
    });
  }, [group]);

  const handleAccountAction = async (data: FormikValues, id: string) => {
    await updateGroup.update({
      accountId: (account as Account)?.id,
      groupId: id,
      updates: {
        ...data,
      },
    });

    navigate(RedirectPaths[RedirectPathsEnum.GROUPS]());
  };

  const handleSubmit = async (data: FormikValues) => {
    try {
      await handleAccountAction(data, group?.id as string);

      toast?.success(t('toast.success'), t('group.edited'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const pathItems = [
    {
      label: account?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id as string),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('groups'),
      url: RedirectPaths[RedirectPathsEnum.GROUPS](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: group?.name,
      url: RedirectPaths[RedirectPathsEnum.GROUPS_EDIT]((group as Group)?.id),
      template: AppBreadCrumbTemplate,
    },
  ];

  const isReadOnlyView =
    cannot(Actions.UPDATE, Subjects.GROUPS) ||
    (account?.isSystem && currentUser?.role.code !== SystemRoles.DEVELOPER);

  if (isLoading) {
    return <LoadingPage message={t('generic.loading')} />;
  }

  return (
    <>
      <AppBreadCrumb model={pathItems} />
      {group && (
        <>
          <h1>{group.name}</h1>
          <GroupsTabs group={group} />
        </>
      )}
      <FlexContainer justify="flex-start">
        <GroupForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          state={queryStateConverter(updateGroup)}
          disabled={isReadOnlyView}
        />
      </FlexContainer>
    </>
  );
};
