import { Conditions } from '@/api/enums';
import { handleAxiosError, isIn } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import {
  Campaign,
  CampaignActionsEnum,
  CampaignStatusesEnum,
  CampaignStatusMessages,
  SendTestEmailFormValues,
} from '@/client/campaigns/types';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import {
  CampaignTemplatesModal,
  SendTestEmailModal,
} from '@/components/campaigns';
import { CampaignsReports } from '@/components/dashboards/admin';
import { GridTable } from '@/components/grid-table';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { ThumbnailsGenerationNotify } from '@/components/thumbnails';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useADConfig,
  useCampaignAction,
  useCampaigns,
  useCampaignsSummary,
  useDeleteCampaign,
  useSaveCampaign,
  useSendTestEmail,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton, AppDropdownButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { ImageCard } from '@/ui/image-card';
import { AppSelectPreviewType } from '@/ui/select-preview';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import {
  copyCampaignData,
  displayCampaignStatus,
  displayCreatedBy,
  getCampaignStatusOptions,
  partialRequests,
  PreviewType,
  queryStateConverter,
} from '@/utils/helpers';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 } from 'uuid';

const StyledDiv = styled.div`
  width: 100%;
  padding-top: var(--default-padding);

  .p-datatable {
    th {
      &.email-sent-icon .p-column-title:before,
      &.users-icon .p-column-title:before,
      &.email-opened-icon .p-column-title:before,
      &.clicked-link-icon .p-column-title:before,
      &.submitted-data-icon .p-column-title:before,
      &.email-reported-icon .p-column-title:before {
        font-family: var(--icon-font-family);
        display: inline-block;
        color: var(--black-main);
        font-size: var(--medium-font-size);
        line-height: var(--medium-line-height);
        padding-right: 2px;
      }

      &.email-sent-icon .p-column-title:before {
        font-weight: 500;
        content: '\f0e0';
      }

      &.users-icon .p-column-title:before {
        font-weight: 500;
        content: '\f007';
      }

      &.email-opened-icon .p-column-title:before {
        font-weight: 500;
        content: '\f2b6';
      }

      &.clicked-link-icon .p-column-title:before {
        font-weight: 900;
        content: '\f245';
      }

      &.submitted-data-icon .p-column-title:before {
        font-weight: 900;
        content: '\f06a';
      }

      &.email-reported-icon .p-column-title:before {
        font-weight: 900;
        content: '\f0a1';
      }

      &.p-highlight .p-column-title:before {
        color: var(--red-main);
      }
    }
  }
`;

const StyledCardGridViewContainer = styled(CardGridContainer)`
  margin: 0;
  width: 100%;
  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const getStatus = (
  status: CampaignStatusesEnum,
  t: TranslationFunctionType,
) => {
  return <span>{t(CampaignStatusMessages[status])}</span>;
};

const getBreadcrumbs = (
  account: Account,
  t: TranslationFunctionType,
): MenuItem[] => {
  return [
    {
      label: account?.name,
      url: RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
      template: AppBreadCrumbTemplate,
    },
    {
      label: t('campaigns'),
      url: RedirectPaths[RedirectPathsEnum.CAMPAIGNS](),
      template: AppBreadCrumbTemplate,
    },
  ];
};

export const CampaignsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { setDialogData } = useContext(DialogContext);
  const account = useAppSelector(selectCurrentAccount);
  const { can } = usePermission();
  const { user } = useAppSelector(selectCurrentUserState);
  const [selectedPreviewType, setSelectedPreviewType] = useState<PreviewType>(
    PreviewType.TABLE,
  );
  const isTable = selectedPreviewType === PreviewType.TABLE;

  const {
    skip,
    sort,
    take,
    apiFilters,
    onSort,
    onFilter,
    onPage,
    onGridPageChange,
    onGridRowChange,
  } = useTable();

  const {
    lastMessage,
    setNotificationParam: setCampaignId,
    notificationParam: notificationCampaignId,
    isConnected,
  } = useNotifications(client.campaigns.campaignsNotifyUrl);

  const [copyState, setCopyState] = useState<LoadingStatuses>(
    LoadingStatuses.IDLE,
  );
  const [showCampaignTemplatesModal, setShowCampaignTemplatesModal] =
    useState(false);

  const [deletingCampaignId, setDeletingCampaignId] = useState<string | null>(
    null,
  );

  const [showSendTestEmailForACampaing, setShowSendTestEmailForACampaing] =
    useState<Campaign | null>(null);

  const saveCampaign = useSaveCampaign();
  const deleteCampaign = useDeleteCampaign();

  const { isLoading, campaigns, refetch } = useCampaigns({
    withTemplates: isTable ? 0 : 1,
    take,
    skip,
    filters: [
      ...apiFilters,
      {
        field: 'account',
        condition: Conditions.EQUAL,
        value: (account as Account)?.id,
      },
    ],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const {
    isLoading: isLoadingSummary,
    campaignsSummary,
    refetch: summaryRefetch,
  } = useCampaignsSummary({
    accountId: account?.id,
  });
  const campaignAction = useCampaignAction();

  useEffect(() => {
    if (!notificationCampaignId) return;
    handleNotifyCampaignLaunch(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotifyCampaignLaunch = async (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'campaign.complete.sync.finished',
        'campaign.complete.sync.failed',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'campaign.complete.sync.finished') {
      await refetch();
      await summaryRefetch();

      toast?.success(t('toast.success'), t('campaign.completed'));
    }

    if (messages?.data?.event === 'campaign.complete.sync.failed') {
      toast?.success(t('toast.success'), t('campaign.not.completed'));
    }

    setCampaignId(undefined);
  };

  const { accountAd } = useADConfig({
    accountId: account?.id,
  });

  const getColumns = (): DataTableColumnType[] => {
    const columns: DataTableColumnType[] = [
      {
        field: 'name',
        header: account?.isSystem
          ? t('campaign.template.name')
          : t('campaign.name'),
        sortable: true,
        filterable: true,
        filters: {
          type: FilterTypeEnum.TEXT,
          placeholder: t('generic.name.search'),
        },
        render: (row: Campaign) => row.name,
      },
    ];

    if (account?.isSystem) {
      columns.push({
        field: 'template',
        header: t('generic.template'),
        sortable: false,
        filterable: true,
        filters: { type: FilterTypeEnum.SELECT_YES_NO },
        render: (row: Campaign) =>
          row.isTemplate ? (
            <AppChip label={t('generic.template')} type="primary" />
          ) : (
            <AppChip label={t('campaign.status.draft')} type="secondary" />
          ),
      });
    }

    if (!account?.isSystem) {
      columns.push(
        {
          field: 'cloneOfName',
          header: t('campaign.template.name'),
          sortable: false,
          filterable: true,
          filters: {
            type: FilterTypeEnum.TEXT,
          },
          render: (row: Campaign) =>
            row.meta?.cloneOfName ? (
              row.meta.cloneOfName
            ) : (
              <div className="group-row">&#8212;</div>
            ),
        },
        {
          field: 'status',
          header: t('generic.status'),
          sortable: true,
          filterable: true,
          filters: {
            field: FilterNamesEnum.CAMPAIGNS_BY_STATUS,
            type: FilterTypeEnum.MULTI_SELECT,
            placeholder: t('generic.status.search'),
            options: getCampaignStatusOptions(t),
          },
          render: (row: Campaign) => getStatus(row.status, t),
        },
        {
          field: 'launchDate',
          header: t('generic.launchDate'),
          sortable: true,
          filterable: false,
          render: (row: Campaign) => (
            <FlexContainer minWidth="max-content" justify="flex-start">
              {row?.launchDate ? (
                <FormatDate date={row?.launchDate} />
              ) : (
                t('campaing.no.LaunchDate')
              )}
            </FlexContainer>
          ),
        },
        {
          field: 'total',
          header: (
            <Tooltip
              position="top"
              target="th.users-icon"
              content={t('users')}
            />
          ),
          iconLabel: t('users'),
          headerClassName: 'users-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) => (row?.total ? row.total : 0),
        },
        {
          field: 'emailsSent',
          header: (
            <Tooltip
              position="top"
              target="th.email-sent-icon"
              content={t('campaign.emailSent')}
            />
          ),
          iconLabel: t('campaign.emailSent'),
          headerClassName: 'email-sent-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) => (row?.emailsSent ? row.emailsSent : 0),
        },
      );

      if (account?.meta?.showCampaignOpenEvents) {
        columns.push({
          field: 'emailsOpen',
          header: (
            <Tooltip
              position="top"
              target="th.email-opened-icon"
              content={t('campaign.emailOpened')}
            />
          ),
          iconLabel: t('campaign.emailOpened'),
          headerClassName: 'email-opened-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) => (row?.emailsOpen ? row.emailsOpen : 0),
        });
      }

      columns.push(
        {
          field: 'clickedLinks',
          header: (
            <Tooltip
              position="top"
              target="th.clicked-link-icon"
              content={t('campaign.clicked')}
            />
          ),
          iconLabel: t('campaign.clicked'),
          headerClassName: 'clicked-link-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) => (row?.clickedLinks ? row.clickedLinks : 0),
        },
        {
          field: 'submittedData',
          header: (
            <Tooltip
              position="top"
              target="th.submitted-data-icon"
              content={t('campaign.submitted')}
            />
          ),
          iconLabel: t('campaign.submitted'),
          headerClassName: 'submitted-data-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) =>
            row?.submittedData ? row.submittedData : 0,
        },
      );
    }

    if (accountAd?.config.emailReportingEnabled) {
      columns.push({
        field: 'emailsReported',
        header: (
          <Tooltip
            position="top"
            target="th.email-reported-icon"
            content={t('campaign.email.reported')}
          />
        ),
        iconLabel: t('campaign.email.reported'),
        headerClassName: 'email-reported-icon',
        sortable: true,
        filterable: false,
        render: (row: Campaign) =>
          row?.emailsReported ? row.emailsReported : 0,
      });
    }

    columns.push({
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center' as const,
      },
      render: (row: Campaign) => {
        if (deletingCampaignId === row.id) {
          return (
            <FlexContainer>
              <ProgressSpinner style={{ width: '24px', height: '24px' }} />
            </FlexContainer>
          );
        }
        return (
          <DataTableActions
            disabled={menuItems(row).length < 1}
            menuItems={menuItems(row)}
          />
        );
      },
    });

    return columns;
  };

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = getColumns().map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const handleCreateNewCampaign = async ({
    isTemplate,
  }: {
    isTemplate?: boolean;
  }) => {
    try {
      const response = await saveCampaign.create({
        name: v4(),
        account: account?.id,
      });

      if (!response?.id) return;

      navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](response?.id), {
        state: { isTemplate },
      });
      toast?.success(t('toast.success'), t('campaign.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleDeleteCampaign = async (campaignId: string) => {
    setDeletingCampaignId(campaignId);
    try {
      await deleteCampaign.delete(campaignId);
      setDeletingCampaignId(null);
      toast?.success(t('toast.success'), t('campaign.deleted'));
      await refetch();
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setDeletingCampaignId(null);
    }
  };

  const sendTestEmail = useSendTestEmail();
  const {
    lastMessage: testMailLastMessage,
    setNotificationParam: setJobId,
    notificationParam: jobId,
  } = useNotifications(client.senderProfiles.senderProfilesJobNotifyUrl);

  useEffect(() => {
    if (!jobId) return;
    handleNotifyEmailSent(testMailLastMessage);
  }, [JSON.stringify(testMailLastMessage)]);

  const handleNotifyEmailSent = (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'sending-profile.email-sent',
        'sending-profile.email-not-sent',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'sending-profile.email-sent')
      toast?.success(t('toast.success'), t('campaign.testEmail.sent'), 5000);
    if (messages?.data?.event === 'sending-profile.email-not-sent')
      toast?.info(t('toast.info'), t('campaign.testEmail.notSent'), 5000);

    setJobId(undefined);
  };

  const handleSendTestEmail = async (data: SendTestEmailFormValues) => {
    if (!showSendTestEmailForACampaing?.id) return;

    try {
      const response = await sendTestEmail.send({
        email:
          data.emailType === 'custom'
            ? data.email
            : (data.admin?.email as string),
        firstName:
          data.emailType === 'custom' ? data.firstName : data.admin?.firstName,
        lastName:
          data.emailType === 'custom' ? data.lastName : data.admin?.lastName,
        position: data.emailType === 'custom' ? data.position : undefined,
        campaignId: showSendTestEmailForACampaing.id,
        emailTemplateId: showSendTestEmailForACampaing?.emailTemplate?.id,
        sendingProfileId: showSendTestEmailForACampaing?.sendingProfile?.id,
        landingPageId: showSendTestEmailForACampaing?.landingPage?.id,
        encodeEmailTemplateImages:
          showSendTestEmailForACampaing?.meta?.encodeEmailTemplateImages,
      });

      setJobId(response.jobId);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }

    setShowSendTestEmailForACampaing(null);
  };

  const menuItems = (campaign: Campaign) => {
    const menu: { items: MenuItem[] }[] = [
      {
        items: [],
      },
    ];

    if (
      !user?.account?.isSystem &&
      campaign.status === CampaignStatusesEnum.DRAFT
    ) {
      menu[0].items.push({
        label: t('camapign.testEmail.send'),
        icon: 'pi pi-envelope',
        command: () => setShowSendTestEmailForACampaing(campaign),
      });
    }

    switch (campaign.status) {
      case CampaignStatusesEnum.ACTIVE:
        if (can(Actions.UPDATE, Subjects.CAMPAIGNS)) {
          menu[0].items.push({
            label: t('generic.complete'),
            icon: 'pi pi-chevron-circle-down',
            command: () =>
              setDialogData({
                show: true,
                type: 'confirmation',
                header: t('campaign.complete'),
                message: t('campaign.complete.confirm'),
                onAccept: async () => {
                  setCampaignId(campaign.id);

                  // Wait for a notify connection to establish
                  await new Promise((resolve) => {
                    const intervalId = setInterval(() => {
                      if (isConnected.current) {
                        clearInterval(intervalId);
                        resolve(true);
                      }
                    }, 100);
                  });
                  try {
                    if (isConnected.current) {
                      await campaignAction.updateStatus({
                        campaignId: campaign.id,
                        action: CampaignActionsEnum.COMPLETE,
                      });

                      await refetch();
                      await summaryRefetch();
                    }
                  } catch (e) {
                    handleAxiosError(e as Error | AxiosError, toast);
                  }
                },
              }),
          });
        }
        break;
      case CampaignStatusesEnum.DRAFT:
        if (can(Actions.UPDATE, Subjects.CAMPAIGNS)) {
          menu[0].items.push({
            label: t('generic.edit'),
            icon: 'pi pi-pencil',
            command: () => {
              navigate(
                RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](campaign.id),
                {
                  state: { isTemplate: !!account?.isSystem },
                },
              );
            },
          });
        }
        break;
      default:
    }

    if (can(Actions.CREATE, Subjects.CAMPAIGNS)) {
      menu[0].items.push({
        label: t('generic.copy'),
        icon:
          copyState === LoadingStatuses.LOADING
            ? 'pi pi-spinner'
            : 'pi pi-copy',
        command: async () => {
          try {
            setCopyState(LoadingStatuses.LOADING);
            const baseCampaign = await client.campaigns.getCampaign(
              campaign?.id as string,
            );
            const users = await partialRequests(
              [isIn('campaigns', [campaign?.id])],
              client.users.getUsers,
            );

            // Filter only active users
            const filteredUsers = users.filter((user) => user.active);

            const response = await saveCampaign.create({
              ...copyCampaignData(baseCampaign, filteredUsers),
              name: `${campaign.name} - ${t('generic.copy')}  ${Date.now()}`,
              launchDate: undefined,
              sendByDate: undefined,
              account: account?.id,
            });
            setCopyState(LoadingStatuses.IDLE);

            if (response && !response?.id) return;

            navigate(
              RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](response?.id),
              {
                state: { isTemplate: !!account?.isSystem },
              },
            );
            toast?.success(t('toast.success'), t('campaign.copied'));
          } catch (e) {
            handleAxiosError(e as Error | AxiosError, toast);
          }
        },
      });
    }

    if (
      ![
        CampaignStatusesEnum.DRAFT,
        CampaignStatusesEnum.PENDING_LAUNCH,
        CampaignStatusesEnum.FAILED_LAUNCH,
      ].includes(campaign.status)
    ) {
      menu[0].items.push(
        ...[
          {
            label: t('generic.reports'),
            icon: 'pi pi-chart-line',
            command: () => {
              navigate(
                RedirectPaths[RedirectPathsEnum.CAMPAIGNS_REPORT](campaign.id),
              );
            },
          },
        ],
      );
    }

    if (can(Actions.DELETE, Subjects.CAMPAIGNS)) {
      menu[0].items.push({
        label: t('generic.delete'),
        icon: 'pi pi-times',
        command: async () =>
          setDialogData({
            show: true,
            type: 'confirmation',
            header: t('campaign.delete'),
            message: t('campaign.delete.confirm'),
            onAccept: async () => await handleDeleteCampaign(campaign.id),
          }),
      });
    }

    return menu[0].items.length ? menu : [];
  };

  const dropdownButtonOptions: MenuItem[] = [
    {
      label: t('campaigns.fromTemplate'),
      icon: 'pi pi-table',
      command: () => setShowCampaignTemplatesModal(true),
    },
    {
      label: t('campaigns.fromScratch'),
      icon: 'pi pi-stop',
      command: () => handleCreateNewCampaign({ isTemplate: false }),
    },
  ];

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
        flex="1 1 50%"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(getColumns())}
          onFilter={onFilter}
          tableName={
            account?.isSystem
              ? TableNamesEnum.CAMPAIGNS_LIST_SYSTEM
              : TableNamesEnum.CAMPAIGNS_LIST_CUSTOMER
          }
        />
      </FlexContainer>
      <FlexContainer
        gap={12}
        justify="flex-end"
        minWidth="min-content"
        flex="1 1 45%"
        wrap="wrap"
      >
        {can(Actions.CREATE, Subjects.CAMPAIGNS) && (
          <>
            {account?.isSystem ? (
              <AppButton
                label={t('campaign.template.new')}
                severity="secondary"
                onClick={() => handleCreateNewCampaign({ isTemplate: true })}
                state={queryStateConverter(saveCampaign)}
              />
            ) : (
              <AppDropdownButton
                menuId="campaign-create"
                label={t('campaign.create')}
                items={dropdownButtonOptions}
                severity="secondary"
                state={queryStateConverter(saveCampaign)}
              />
            )}
          </>
        )}
        {isTable && (
          <DataTableColumnsMultiselect
            columns={getColumns()}
            tableName={
              account?.isSystem
                ? TableNamesEnum.CAMPAIGNS_LIST_SYSTEM
                : TableNamesEnum.CAMPAIGNS_LIST_CUSTOMER
            }
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            defaultVisibleColumns={defaultVisibleColumns}
            alwaysVisibleColumns={alwaysVisibleColumns}
          />
        )}
        <AppSelectPreviewType
          tableName="campaignsTable"
          accountId={account?.id}
          userId={user?.id}
          selectedPreviewType={selectedPreviewType}
          setSelectedPreviewType={setSelectedPreviewType}
        />
      </FlexContainer>
    </DataTableToolbar>
  );

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(account as Account, t)} />
      <FlexContainer align="baseline" justify="space-between" className="mt-4">
        <h1 className="mb-0">{t('campaigns')}</h1>
        {/* TODO: remove currentUser's account check when templates are set on prod */}
        {!account?.isSystem && user?.account.isSystem && (
          <AppButton
            label={t('campaigns.catalogue')}
            severity="secondary"
            state={isLoading ? LoadingStatuses.LOADING : LoadingStatuses.IDLE}
            onClick={() =>
              navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_CATALOGUE]())
            }
          />
        )}
      </FlexContainer>
      {can(Actions.READ, Subjects.ACCOUNT_CAMPAIGNS_SUMMARY) &&
        !isLoadingSummary &&
        campaignsSummary &&
        !account?.isSystem &&
        account && (
          <CampaignsReports
            campaignsSummary={campaignsSummary}
            isLoading={isLoadingSummary}
            t={t}
            className="mt-4"
          />
        )}
      <StyledDiv>
        {isTable ? (
          <DataTable
            data={campaigns?.result}
            count={campaigns?.count as number}
            isLoading={isLoading}
            toolbar={toolbar}
            columns={getColumns()}
            visibleColumns={visibleColumns}
            rows={take}
            skip={skip}
            onPage={onPage}
            onSort={onSort}
            sort={sort}
          />
        ) : (
          <GridTable
            count={campaigns?.count}
            take={take}
            skip={skip}
            toolbarContent={toolbar}
            onPageChange={onGridPageChange}
            onRowsChange={onGridRowChange}
            isLoading={isLoading}
            rowsPerPage={[8, 16]}
          >
            {!isLoading && !!campaigns?.result.length && (
              <>
                <ThumbnailsGenerationNotify
                  refetch={refetch}
                  templates={campaigns?.result.map(
                    (campaign: Campaign) => campaign.emailTemplate,
                  )}
                />
                <StyledCardGridViewContainer>
                  {campaigns?.result.map((campaign: Campaign) => (
                    <ImageCard
                      key={campaign.id}
                      id={campaign.id}
                      title={campaign.name}
                      status={
                        !account?.isSystem
                          ? displayCampaignStatus(t, campaign.status)
                          : undefined
                      }
                      author={displayCreatedBy(t, campaign.createdBy)}
                      thumbnail={campaign.emailTemplate?.thumbnail?.signedUrl}
                      templateType="email"
                      interactionType="withActions"
                      disabledActions={menuItems(campaign).length < 1}
                      actions={menuItems(campaign)}
                      isTemplate={campaign.isTemplate}
                    />
                  ))}
                </StyledCardGridViewContainer>
              </>
            )}
          </GridTable>
        )}
      </StyledDiv>
      <CampaignTemplatesModal
        visible={showCampaignTemplatesModal}
        onHide={() => setShowCampaignTemplatesModal(false)}
      />
      <SendTestEmailModal
        email={user?.email as string}
        visible={!!showSendTestEmailForACampaing}
        onSubmit={handleSendTestEmail}
        onHide={() => setShowSendTestEmailForACampaing(null)}
        extended={false}
        withName
      />
    </>
  );
};
