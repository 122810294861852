import { unauthorizedInterceptor } from '@/client/interceptors';
import { ApiClientConfigType } from '@/client/types';
import i18n from '@/i18n';
import axios, { AxiosInstance } from 'axios';
import momentTimezone from 'moment-timezone';

const axiosClientFactory = (config: ApiClientConfigType): AxiosInstance => {
  const instance = axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
  });

  instance.defaults.timeout = 180000;
  instance.defaults.headers.common = {
    'x-accept-language': i18n.language,
    'x-accept-timezone': momentTimezone.tz.guess(),
  };

  if (config.onUnauthorized) {
    instance.interceptors.response.use(
      undefined,
      unauthorizedInterceptor(config.onUnauthorized),
    );
  }

  return instance;
};

export default axiosClientFactory;
